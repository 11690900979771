import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Happy New Year!`}</em></p>
    <p>{`Rest Day.  The Ville will be closed today. We will reopen Monday for all
regularly scheduled classes at both locations!`}</p>
    <p><strong parentName="p">{`*`}{`Our 2nd Annual Pre Open in house competition will start January 2nd
so get ready! RX Men, RX Women, scaled, and masters (50+) divisions. If
you would like to register please email Daniel at:
Daniel\\@crossfitlouisvilleeast.com with which division you’d like to
enter. Each workout will a daily WOD and you’ll be able to do it during
class so there’s no reason not to enter! Each workout will also be done
only once so unlike the Open it won’t interfere with our regular
programming.`}</strong></p>
    <p><em parentName="p">{`*`}{`The next Strength Program will start January 7th. For more
information email Eric at: Fallscitystrength\\@gmail.com.`}</em></p>
    <p><em parentName="p">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports Nutrition
seminar will Dawn from Sports Nutrition 2 Go in Cincinnati. Metabolic
Testing will be offered before and after the seminar as well!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      